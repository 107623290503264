import React from "react";
import { ChakraProvider, Center, Image, Heading } from "@chakra-ui/react";
import infoImage from "./public/information.png";

const App = () => (
  <ChakraProvider resetCSS>
    <Center
      h="100vh"
      w="100vw"
      flexDirection="column"
      backgroundColor="gray.800"
      bgGradient="linear(to top right, gray.100,gray.100)"
    >
      <Image
        height="300px"
        width="300px"
        src="https://pub-353d86fbfa4f4f21b7e94ecf12297de3.r2.dev/ARO_ROC_LOGO.svg"
        h="22vh"
        objectFit="cover"
        w="auto"
      />
        <Image
            src={infoImage}
            h="7vh"
            objectFit="cover"
            w="auto"
        />
    </Center>
  </ChakraProvider>
);

export default App;
